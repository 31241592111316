/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable eqeqeq */
/* eslint-disable operator-linebreak */
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Animation from '../../components/MusicCurriculum/Sections/Animation';
import Footer from '../../components/MusicCurriculum/Sections/Footer';
import Header from '../../components/MusicCurriculum/Sections/Header';
import Panels from '../../components/MusicCurriculum/Sections/Panels';
import Tabs from '../../components/MusicCurriculum/Tabs';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetMusicCurriculum from '../../hooks/Curriculum/useGetMusicCurriculum';
import useGetMusicCurriculumOverview from '../../hooks/Curriculum/useGetMusicCurriculumOverview';
import { organisationLoggedInAs, user } from '../../utils/reactiveVars';

const MusicCurriculum = () => {
  const { year, term } = useParams();
  const [selectedYear, setSelectedYear] = useState(1);

  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id;
  const { data: { musicCurriculum = [] } = {}, loading } =
    useGetMusicCurriculum(organisationRef);

  const { data: { musicCurriculumOverview } = {} } =
    useGetMusicCurriculumOverview();

  const sortedYears = useMemo(() => {
    const musicCurriculumCopy = [...musicCurriculum];

    return musicCurriculumCopy
      .filter((item) => !item.adminOnly || (item.adminOnly && user().isAdmin))
      .sort((a, b) => a.year - b.year);
  }, [musicCurriculum]);

  const selectedYearObj = useMemo(() => {
    return sortedYears.find((elm) => elm.year == selectedYear);
  }, [sortedYears, selectedYear]);

  loading && toast.info('Loading Music curriculum...', { toastId: 'loading' });

  useEffect(() => {
    if (sortedYears && sortedYears.length >= 1) {
      setSelectedYear(year || sortedYears[0].year);
    }
  }, [sortedYears, year]);

  return (
    <>
      {musicCurriculumOverview && (
        <Header
          title={musicCurriculumOverview.title}
          subtitle={musicCurriculumOverview.subtitle}
        />
      )}

      <section className="main__container mt-40">
        {musicCurriculumOverview && (
          <Footer musicCurriculumOverview={musicCurriculumOverview} />
        )}
        {musicCurriculum.length > 0 && (
          <div className="mc__tabs">
            <p className="mc__tabs__year">Year</p>
            <Tabs
              musicCurriculum={musicCurriculum}
              handleSelectedYear={setSelectedYear}
              selectedYear={selectedYearObj}
            />
            <Panels
              selectedYear={selectedYearObj}
              selectedTerm={term}
              yearParamExists={!!year}
            />
          </div>
        )}
      </section>
      {musicCurriculumOverview && (
        <Animation media={musicCurriculumOverview.media} />
      )}
      <div className="mb-40" />
    </>
  );
};

export default withPageWrapper({ pageTitle: 'Music Curriculum | Sparkyard' })(
  MusicCurriculum
);
