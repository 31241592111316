/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-danger */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable eqeqeq */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import Term from './Panels/Term';

const Panels = ({ selectedYear, selectedTerm, yearParamExists }) => {
  const sortedTerms = useMemo(() => {
    return [...selectedYear?.terms].sort((a, b) => a.term - b.term);
  }, [selectedYear]);

  const getTermId = useCallback(
    (termNumber) => sortedTerms.find((term) => term.term === termNumber),
    [sortedTerms]
  );

  const [openPanelIds, setopenPanelIds] = useState(
    selectedTerm ? [getTermId(selectedTerm).id] : null
  );

  useEffect(() => {
    setopenPanelIds([getTermId(selectedTerm)?.id]);
  }, [selectedTerm, getTermId]);

  const handlePanelIds = (id) => {
    if (openPanelIds?.includes(id)) {
      const newIds = openPanelIds.filter((value) => value != id);
      return setopenPanelIds(newIds);
    }
    openPanelIds
      ? setopenPanelIds([...openPanelIds, id])
      : setopenPanelIds([id]);
  };

  const isOpen = (id) => openPanelIds?.includes(id);
  const termExist = (termNumber) =>
    sortedTerms.find((term) => term.term === termNumber);
  const findTermIndex = (termNumber) =>
    sortedTerms.findIndex((term) => term.term === termNumber);

  const termRefs = useRef(null);
  const yearRef = useRef(null);

  useEffect(() => {
    if (selectedTerm && termExist(selectedTerm)) {
      return termRefs.current.children[
        findTermIndex(selectedTerm)
      ].scrollIntoView();
    }
    if (yearParamExists) {
      yearRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div className="tab-content" id="mcTabContent">
      <div
        className="tab-pane fade show active"
        id="first"
        role="tabpanel"
        aria-labelledby="first-tab"
        ref={yearRef}
      >
        <div className="mc__tabs__first">
          {selectedYear.pdf && (
            <div className="text__sm text__underline text__black mb-md-3 mb-4">
              <a href={selectedYear.pdf}>
                {selectedYear.pdfCopy ||
                  `Everything you need to cover the Music Curriculum for Year
            ${selectedYear.year == '0' ? 'Reception' : selectedYear.year}`}
              </a>
            </div>
          )}
          {selectedYear.pdf2 && (
            <div className="text__sm text__underline text__black mb-md-3 mb-4">
              <a href={selectedYear.pdf2}>
                {selectedYear.pdf2Copy ||
                  `Everything you need to cover the Music Curriculum for Year
            ${selectedYear.year == '0' ? 'Reception' : selectedYear.year}`}
              </a>
            </div>
          )}
          <div ref={termRefs}>
            {sortedTerms?.map((term) => (
              <Term
                isOpen={isOpen}
                term={term}
                handlePanelIds={handlePanelIds}
                key={term.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panels;
