/* eslint-disable no-plusplus */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useEffect } from 'react';
import useComponentSize from '@rehooks/component-size';
import { Text, Stage, Layer, Line as KonvaLine } from 'react-konva';
import { useObserver } from 'mobx-react-lite';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import Line from './Line';
import { Line as LineType, Group, Phrase, Page } from '../types';
import useHighlightedPhrase from '../hooks/useHighlightedPhrase';
import useStores from '../hooks/useStores';
import useFontLoaded from '../hooks/useFontLoaded';
import useScale from '../hooks/useScale';
import URLImage from './UrlImage';
import SlideSwitcher from './SlideSwitcher';
import { isPlayerFullscreen } from '../../utils/reactiveVars';
import { colMap } from '../utils/colMap';
import SlideContainer from './Styles/SlideContainer';
import SlideElement from './Styles/SlideElement';
import CanvasContainer from './Styles/CanvasContainer';
import BackgroundContainer from './Styles/BackgroundContainer';
import Background from './Styles/Background';
import Controls from './Styles/Controls';
import { splitByThreshold } from '../../utils/helpers';

// const fontFamily = 'CenturyGothic';
// const titleFamily = 'FuturBook';
// const copyrightFamily = 'Open Sans';

type Props = {
  id: number;
  title: string;
  page: Page;
  milliseconds: number;
  height: number | string;
  width: number | string;
  currentSlide: number;
  cloud: boolean;
  onClick?: (id: number) => void;
  background: string;
  totalSlides: number;
};

export const overrideSystem = { overrideSystem: true, ignoreInputFields: true };

const Slide = ({
  id,
  title,
  page,
  milliseconds,
  height,
  width,
  currentSlide,
  cloud,
  onClick,
  background,
  totalSlides,
}: Props): JSX.Element => {
  const { mediaPlayerStore } = useStores();
  const containerRef = useRef<HTMLDivElement>(null!);

  // const [state, setState] = useState(page.groups);

  // trigger rerender so text is displayed properly when
  // view is switched while song is paused
  useEffect(() => {
    if (!mediaPlayerStore.playing) {
      mediaPlayerStore.setPlaying(true);
      mediaPlayerStore.setPlaying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaPlayerStore.plainTextView]);

  const containerDimensions = useComponentSize(containerRef);
  const scale = useScale(containerDimensions);
  const [highlightedPhrases, phrases] = useHighlightedPhrase(
    page.groups,
    milliseconds
  );

  const isPlainText = mediaPlayerStore.plainTextView === true;
  const fontFamily = isPlainText
    ? 'CenturyGothic'
    : mediaPlayerStore.song.styleBodyFont;
  const titleFamily = isPlainText
    ? 'FuturBook'
    : mediaPlayerStore.song.styleTitleFont;
  const copyrightFamily = 'Open Sans';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fontLoaded, fontError] = useFontLoaded(
    page.id,
    mediaPlayerStore.song.styleBodyFont
  );

  const [titleFontLoaded, titleFontError] = useFontLoaded(
    page.id,
    mediaPlayerStore.song.styleTitleFont
  );
  const [copyrightFontLoaded, copyrightFontError] = useFontLoaded(
    page.id,
    copyrightFamily
  );

  const plainTextViewTextColor = 'rgb(45, 43, 85)';
  const textPrimaryColor = mediaPlayerStore.plainTextView
    ? plainTextViewTextColor
    : mediaPlayerStore.song.styleCol1Unhighlighted;

  let totalLineCount = 0;

  // eslint-disable-next-line no-return-assign
  page.groups.every((group) => (totalLineCount += group.lines.length));

  const shouldReduceLineHeight =
    (cloud && totalLineCount > 8 && !mediaPlayerStore.plainTextView) ||
    (currentSlide === 0 && totalLineCount > 8);

  const hasCloudAndMultipleGroups =
    page.groups.length > 2 && cloud && !mediaPlayerStore.plainTextView;

  const hasMultipleGroupsAndIsFirstPage =
    currentSlide === 0 && page.groups.length > 2;

  const hasCloudAndIsFirstPage =
    cloud &&
    totalLineCount > 6 &&
    currentSlide === 0 &&
    !mediaPlayerStore.plainTextView;

  const parsedTitle =
    mediaPlayerStore.song.styleTitleSize >= 60
      ? splitByThreshold(title, 28)
      : [title];

  const getYOffset = (
    lineIdx: number,
    groupIdx: number,
    groupCount: number,
    groups: Group[]
  ) => {
    let lineCount = 0;

    groups.slice(0, groupIdx + 1).forEach((group, i) => {
      if (i === groupIdx) {
        lineCount += lineIdx;
        return;
      }
      lineCount += group.lines.length;
    });

    const hasChorusImage = cloud && !mediaPlayerStore.plainTextView;
    const isMultipleGroups = groupCount > 2;
    // let lineSpacing = totalLineCount > 10 ? 37 : 45;
    let lineSpacing = totalLineCount > 10 ? 37 : 50;
    if (shouldReduceLineHeight) {
      lineSpacing = 32;
    }
    // const groupSpacing = isMultipleGroups ? 10 * groupIdx - 5 : 20 * groupIdx;
    let groupSpacing = isMultipleGroups ? 10 * groupIdx : 20 * groupIdx;

    let firstLinePosition = currentSlide === 0 ? 110 : 10;

    if (hasMultipleGroupsAndIsFirstPage) {
      groupSpacing = groupIdx;
      lineSpacing = 45;
    }
    if (hasCloudAndMultipleGroups || hasCloudAndIsFirstPage) {
      lineSpacing = 40;
    }

    if (hasCloudAndMultipleGroups && currentSlide === 0) {
      groupSpacing = groups[groupIdx - 1]?.stylePadding || groupSpacing;
    }

    if (shouldReduceLineHeight) {
      lineSpacing = 32;
      firstLinePosition = 120;
    }

    // lineSpacing =
    //   (mediaPlayerStore.song.styleBodyLeading || page.styleBodyLeading) +
    //   (mediaPlayerStore.song.styleBodySize || page.styleBodySize) + 4;

    if (hasChorusImage) {
      firstLinePosition = currentSlide === 0 ? 80 : 30;
    }
    if (groupIdx !== 0) firstLinePosition += groups[0]?.stylePadding;

    if (cloud && mediaPlayerStore.plainTextView) {
      firstLinePosition = currentSlide === 0 ? 100 : 50;
    }

    let stylePadding = groups[groupIdx]?.stylePadding;
    if (
      cloud &&
      mediaPlayerStore.plainTextView &&
      groupIdx === 0 &&
      groupCount > 1
    ) {
      stylePadding = 0;
    }

    if (parsedTitle.length > 1 && currentSlide === 0) {
      firstLinePosition += mediaPlayerStore.plainTextView ? 20 : 60;
    }

    return (
      firstLinePosition +
      lineCount * lineSpacing +
      (stylePadding || groupSpacing)
    );
  };

  const playOrPauseKey = [' '];
  const instrumentalTrackKey = ['I'];
  const vocalTrackKey = ['V'];
  const previousPageKey = ['ArrowLeft'];
  const nextPageKey = ['ArrowRight'];

  useKeyboardShortcut(
    playOrPauseKey,
    () => {
      if (isPlayerFullscreen()) {
        return mediaPlayerStore.setPlaying(!mediaPlayerStore.playing);
      }
    },
    overrideSystem
  );

  useKeyboardShortcut(
    previousPageKey,
    () => {
      if (isPlayerFullscreen() && currentSlide > 0) {
        return mediaPlayerStore.switchSlide(-1, currentSlide);
      }
    },
    overrideSystem
  );

  useKeyboardShortcut(
    nextPageKey,
    () => {
      if (isPlayerFullscreen() && currentSlide !== totalSlides - 1) {
        return mediaPlayerStore.switchSlide(1, currentSlide);
      }
    },
    overrideSystem
  );

  useKeyboardShortcut(
    instrumentalTrackKey,
    () => {
      if (isPlayerFullscreen()) {
        return mediaPlayerStore.switchTrack(
          1,
          mediaPlayerStore.progress.playedSeconds
        );
      }
    },
    overrideSystem
  );

  useKeyboardShortcut(
    vocalTrackKey,
    () => {
      if (isPlayerFullscreen()) {
        return mediaPlayerStore.switchTrack(
          0,
          mediaPlayerStore.progress.playedSeconds
        );
      }
    },
    overrideSystem
  );

  let footerTextIdx = 0;
  page.infoFooter1.length > 0 && (footerTextIdx += 1);
  page.infoFooter2.length > 0 && (footerTextIdx += 1);

  const footerText = `Words and Music by ${mediaPlayerStore.song.infoAuthor} ${mediaPlayerStore.song.infoCopyrightFormatted}, CCLI Song No. ${mediaPlayerStore.song.infoCCLI}`;
  const FooterTextToArray = footerText.split('<newline />');
  const copyrights = mediaPlayerStore.song.infoCopyrightFormatted
    ? mediaPlayerStore.song.infoCopyrightFormatted.split('<newline />')
    : [];
  if (mediaPlayerStore.song.infoAuthor) {
    copyrights[0] = `Words and Music by ${mediaPlayerStore.song.infoAuthor} ${copyrights[0]}`;
  }
  copyrights[copyrights.length - 1] = `${
    copyrights[copyrights.length - 1]
  }, CCLI Song No. ${mediaPlayerStore.song.infoCCLI}`;

  const getFontsize = () => {
    const fontSize = page.styleBodySize || mediaPlayerStore.song.styleBodySize;
    if (shouldReduceLineHeight) return fontSize / 1.3;
    if (hasCloudAndMultipleGroups) return fontSize / 1.1;
    if (hasMultipleGroupsAndIsFirstPage) return fontSize / 1.05;
    if (hasCloudAndIsFirstPage) return fontSize / 1.15;
    return totalLineCount > 10 ? fontSize / 1.15 : fontSize;
  };

  const hasCloud = cloud && !mediaPlayerStore.plainTextView;
  const cloudPosX = page.cloudPosX - 120;
  const cloudPosY = page.cloudPosY - 80 > 0 ? page.cloudPosY - 80 : 0;

  let FooterTextStartingPosOffset = 530 - FooterTextToArray.length * 15;

  const footerOffset =
    getYOffset(0, page.groups.length + 1, page.groups.length, page.groups) +
    (hasCloud ? 80 : 0);
  FooterTextStartingPosOffset =
    FooterTextStartingPosOffset > footerOffset
      ? FooterTextStartingPosOffset
      : footerOffset;

  const firstPageLinePosition = parsedTitle.length > 1 ? 120 : 70;

  const longestInfoNameLength = Math.max(
    ...page.groups.map((group) => group.infoName?.length || 0)
  );

  const song = mediaPlayerStore.song as CustomSong;

  return useObserver(() => (
    <SlideContainer
      ref={containerRef}
      scale={scale}
      height={height}
      width={width}
      showSlide={mediaPlayerStore.showSlide}
    >
      <SlideElement
        scale={scale}
        containerDimensions={containerDimensions}
        onClick={() => {
          if (onClick) onClick(id);
        }}
      >
        <CanvasContainer
          scale={scale}
          containerDimensions={containerDimensions}
          padding={[
            mediaPlayerStore.song.styleMarginTop,
            mediaPlayerStore.song.styleMarginRight,
            mediaPlayerStore.song.styleMarginBottom,
            mediaPlayerStore.song.styleMarginLeft,
          ]}
        >
          <Stage
            width={1023.65 * scale}
            height={622 * scale}
            scaleX={scale}
            scaleY={scale}
          >
            <Layer>
              <>
                {/* Title for non-plain text */}
                {page.showPageHeader &&
                  (titleFontLoaded || titleFontError) &&
                  currentSlide === 0 &&
                  parsedTitle.map((line, idx) => (
                    <Text
                      key={line}
                      y={
                        30 * (idx + 1) +
                        (mediaPlayerStore.plainTextView ? 15 : 30) * idx
                      }
                      x={mediaPlayerStore.plainTextView ? 20 : 60.54}
                      wrap="word"
                      text={line}
                      fill={
                        mediaPlayerStore.plainTextView
                          ? plainTextViewTextColor
                          : `#${mediaPlayerStore.song.styleTitleCol}`
                      }
                      fontSize={
                        mediaPlayerStore.plainTextView
                          ? mediaPlayerStore.song.styleTitleSize * 0.666
                          : mediaPlayerStore.song.styleTitleSize
                      }
                      fontFamily={
                        mediaPlayerStore.plainTextView
                          ? fontFamily
                          : titleFamily
                      }
                    />
                  ))}

                {/* Chorus cloud/text+line in plaintext view */}

                {cloud &&
                  (!mediaPlayerStore.plainTextView ? (
                    <URLImage
                      src={page.clouds.length ? page.clouds[0].filename : ''}
                      x={cloudPosX}
                      y={cloudPosY}
                      // x={0}
                      // y={0}
                    />
                  ) : (
                    <Text
                      x={0}
                      y={0}
                      wrap="word"
                      text="Chorus"
                      fill={textPrimaryColor}
                      fontSize={40}
                      fontFamily={fontFamily}
                    />
                  ))}
                {mediaPlayerStore.plainTextView &&
                  (page.showPageHeader || cloud) && (
                    <KonvaLine
                      x={20}
                      y={currentSlide === 0 ? firstPageLinePosition : 40}
                      points={[0, 0, 700, 0]}
                      strokeWidth={2.5}
                      stroke={textPrimaryColor}
                    />
                  )}

                {/* Lyrics */}
                {fontLoaded &&
                  page.groups.map((group: Group, groupIdx: number) =>
                    group.lines.map((line: LineType, lineIdx: number) => {
                      // const yOffset = 120 + 44.5 * idx + (idx1 === 0 ? 0 : 55);
                      const yOffset = getYOffset(
                        lineIdx,
                        groupIdx,
                        page.groups.length,
                        page.groups
                      );

                      return (
                        <Line
                          {...line}
                          key={line.id}
                          infoName={group.infoName}
                          highlightInfoName={
                            group.styleGroupCol === 'highlighted'
                          }
                          longestInfoNameLength={longestInfoNameLength}
                          position={lineIdx}
                          // yPosition={yPos}
                          yPosition={yOffset}
                          highlightedPhrases={highlightedPhrases}
                          onClick={(phrase: Phrase) => {
                            mediaPlayerStore.setSeeking(true);
                            mediaPlayerStore.setLastPlayheadPositionMs(
                              Math.round(
                                mediaPlayerStore.progress.playedSeconds * 1000
                              )
                            );
                            mediaPlayerStore.setCurrentPlayheadPositionMs(
                              Math.round(phrase.milliseconds)
                            );
                            // Single clicks move song to line - doesnt affect "store.playing"
                            // e.g. if song is playing,
                            // playback continues, if paused, remains paused
                            mediaPlayerStore.setJumpTo({
                              phraseStarts: phrase.milliseconds,
                              playSinglePhrase: false,
                            });
                            mediaPlayerStore.setProgress({
                              ...mediaPlayerStore.progress,
                              played: mediaPlayerStore.jumpTo.phraseStarts,
                            });
                            // Seeks the progress bar
                            // if player is paused (onProgress won't fire after seeking)
                          }}
                          onDblClick={(phrase: Phrase) => {
                            // Double clicks move to line
                            // and play song no matter "store.playing" state
                            // double click will *only* play the selected line, then pause
                            let phraseEnds = page.millisecondsStop;

                            const phraseIndex = phrases
                              .map((phrase) => phrase.id)
                              .indexOf(phrase.id);

                            if (lineIdx < group.lines.length - 1) {
                              // if line was split with wraplines() the next line(s) will have
                              // the same milliseconds value
                              // find the next phrase without the same milliseconds stop value
                              phraseEnds =
                                phrases
                                  .slice(phraseIndex)
                                  .find(
                                    (p) =>
                                      p.milliseconds !== phrase.milliseconds
                                  )?.milliseconds || page.millisecondsStop;
                            } else {
                              phraseEnds += 150;
                            }

                            mediaPlayerStore.setLastPlayheadPositionMs(
                              Math.round(
                                mediaPlayerStore.progress.playedSeconds * 1000
                              )
                            );
                            mediaPlayerStore.setCurrentPlayheadPositionMs(
                              Math.round(phrase.milliseconds)
                            );

                            mediaPlayerStore.setJumpTo({
                              phraseStarts: phrase.milliseconds,
                              phraseEnds,
                              playSinglePhrase: true,
                              newPhraseID: phrase.id,
                            });
                            if (!mediaPlayerStore.playing) {
                              mediaPlayerStore.setShouldUpdateHit(false);
                            }
                            mediaPlayerStore.setPlaying(true);
                          }}
                          isPlaying={mediaPlayerStore.playing}
                          plainTextView={mediaPlayerStore.plainTextView}
                          fontFamily={fontFamily}
                          fontSize={getFontsize()}
                          textPrimaryColor={textPrimaryColor}
                          textHighlightColor={
                            mediaPlayerStore.plainTextView
                              ? 'rgb(254, 0, 0)'
                              : `#${song[colMap[group.styleColIndex]]}`
                          }
                          totalLineCount={totalLineCount}
                        />
                      );
                    })
                  )}

                {page.infoFooter1.length > 0 && (
                  <Text
                    y={
                      getYOffset(
                        0,
                        page.groups.length,
                        page.groups.length,
                        page.groups
                      ) + (hasCloud ? 50 : 0)
                    }
                    x={60}
                    key={Number(copyrightFontLoaded)}
                    wrap="word"
                    fill={textPrimaryColor}
                    text={page.infoFooter1}
                    fontSize={14}
                    fontFamily={fontFamily}
                    fontStyle="italic"
                  />
                )}
                {page.infoFooter2.length > 1 && (
                  <Text
                    y={
                      getYOffset(
                        1,
                        page.groups.length + 1,
                        page.groups.length,
                        page.groups
                      ) + (hasCloud ? 50 : 0)
                    }
                    x={60}
                    key={Number(copyrightFontLoaded)}
                    wrap="word"
                    fill={textPrimaryColor}
                    text={page.infoFooter2}
                    fontSize={18}
                    fontFamily={fontFamily}
                    fontStyle="italic"
                  />
                )}
                {/*page.showPageFooter &&
                  (copyrightFontLoaded || copyrightFontError) &&
                  FooterTextToArray.map((footerTextLine, idx) => (
                    <Text
                      y={FooterTextStartingPosOffset - idx * 15}
                      x={60}
                      key={Number(copyrightFontLoaded)}
                      wrap="word"
                      fill={textPrimaryColor}
                      text={footerTextLine}
                      fontSize={mediaPlayerStore.plainTextView ? 14 : 10}
                      fontFamily={"'Open Sans'"}
                    />
                  ))*/}

                {page.showPageFooter &&
                  (copyrightFontLoaded || copyrightFontError) &&
                  copyrights.map((copyright, idx) => (
                    <Text
                      y={
                        FooterTextStartingPosOffset -
                        (copyrights.length - idx) * 15
                      }
                      x={60}
                      key={Number(copyrightFontLoaded)}
                      wrap="word"
                      fill={textPrimaryColor}
                      text={copyright}
                      fontSize={mediaPlayerStore.plainTextView ? 14 : 10}
                      fontFamily={"'Open Sans'"}
                    />
                  ))}
              </>
            </Layer>
          </Stage>
        </CanvasContainer>
        <BackgroundContainer
          scale={scale}
          containerDimensions={containerDimensions}
        >
          <Background
            scale={scale}
            containerDimensions={containerDimensions}
            plainTextView={mediaPlayerStore.plainTextView}
            background={background}
          />
        </BackgroundContainer>
      </SlideElement>
      <Controls>
        <SlideSwitcher
          gridPosition={4}
          currentSlide={currentSlide}
          totalSlides={totalSlides}
        />
      </Controls>
    </SlideContainer>
  ));
};

export default Slide;
