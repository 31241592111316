/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import RouterLinkOrNot from '../RouterLinkOrNot';
import SongItem from '../SongItem';

const Quote = ({
  songId,
  songs,
  image,
  text,
  description,
  buttonText,
  buttonLink,
  buttonBgColor = '',
}) => {
  const textSpanAdded = useMemo(() => {
    return text + '<span class="quote__closing-icon" />';
  }, [text]);

  return (
    <div className="quote-container">
      <div className={classNames(['quote', { 'quote--song': songId }])}>
        {(songId || image) && (
          <div className="quote__media">
            {image && !songId ? (
              <img src={image} alt="" className="quote__img" />
            ) : songId ? (
              <SongItem
                id={songId}
                albumArt={image}
                songs={songs}
                // signingVideos={true}
              />
            ) : (
              <></>
            )}
          </div>
        )}
        <div className="quote__text">
          <div>
            <div
              className="quote__text-title"
              dangerouslySetInnerHTML={{ __html: textSpanAdded }}
            />
            {description && (
              <div
                className="quote__text-description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {buttonText && buttonLink && (
              <RouterLinkOrNot
                href={buttonLink}
                style={{
                  backgroundColor: buttonBgColor,
                }}
                className="quote-cta"
              >
                {buttonText}
              </RouterLinkOrNot>
            )}
          </div>
        </div>
      </div>
      {buttonText && buttonLink && (
        <RouterLinkOrNot
          href={buttonLink}
          style={{
            backgroundColor: buttonBgColor,
          }}
          className="quote-cta"
        >
          {buttonText}
        </RouterLinkOrNot>
      )}
    </div>
  );
};

export default Quote;
