import React, { useMemo } from 'react';

const Footer = ({ musicCurriculumOverview }) => {
  const links = useMemo(() => {
    const linkConfigs = [
      {
        key: 'pdf',
        label:
          'Complete Curriculum Overview and Skills Progression (Reception)',
      },
      {
        key: 'howTo',
        label: 'Reception Music Curriculum and the 7 Areas of Learning',
      },
      {
        key: 'pdf2',
        label: 'Complete Curriculum Overview and Skills Progression (Y1-6)',
      },
      {
        key: 'pdf3',
        label: 'Musical Vocabulary Bank (Reception-Y6)',
      },
    ];

    return linkConfigs
      .filter((config) => musicCurriculumOverview[config.key])
      .map((config) => ({
        label: config.label,
        link: musicCurriculumOverview[config.key],
      }));
  }, [musicCurriculumOverview]);

  return (
    <div className="bg__green-meadow p-3 pb-13 text__white mb-20">
      {links.map((elm, index) => (
        <a
          key={elm.key}
          href={elm.link}
          target="_blank"
          rel="noreferrer"
          className={`d-flex justify-content-between text__white${
            index < links.length - 1 ? ' mb-3' : ''
          }`}
        >
          <p className="text__fs-14 mb-0 mr-2">{elm.label}</p>
          <i className="fa fa-chevron-right text__fs-19" />
        </a>
      ))}
    </div>
  );
};

export default Footer;
